* {
  color: white;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app {
  height: 100%;
  background-color: #172634;
  display: flex;
  flex-direction: column;
}


.side-bar {
  background-color: #202123;
  height: 100vh;
  width: 244px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  border: transparent;
  background-color: transparent;
  border-radius: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.info {
  color: rgba(255, 255, 255, 0.5);
  padding-bottom: 4px;
  font-size: 11px;
}

.main {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto; 
}

.welcome-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.info-button {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  transition: opacity 0.3s ease; 
}

.info-button:hover {
  opacity: 0.7; 
}

.welcome-modal {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: #121F2F;
  color: white; 
  border-radius: 15px;
  padding: 15px;
  max-width: 40%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); 
  transition: opacity 0.3s ease; 
}

.modal-section {
  margin: 10px 20px;
  flex: 1; 
  display: flex;
  flex-direction: column; 
  align-items: center;
  text-align: center; 
}

.modal-section h3 {
  margin-top: 0; 
}

@media (max-width: 600px) {
  .welcome-modal {
    display: flex;
      align-items: center;
    text-align: center;
    flex-direction: column; 
    max-width: 900%; 
    padding: 10px;
  }

  .modal-section {
    margin: 10px 0px;
    padding: 10px;
  }

  .modal-section h3 {
    margin: 0px;
    font-size: 1.1rem;
  }

  .modal-section p {
    font-size: 0.9rem;
  }

}

.bottom-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.input-container {
  position: relative;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
}

input {
  font-size: 20px;
  width: 90%;
  border: none;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 12px 25px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0 54px 55px,
      rgba(0, 0, 0, 0.05) 0 -12px 30px,
      rgba(0, 0, 0, 0.05) 0 54px 55px,
      rgba(0, 0, 0, 0.05) 0 4px 6px,
      rgba(0, 0, 0, 0.05) 0 12px 3px,
      rgba(0, 0, 0, 0.05) 0 -3px 5px;
}

input:focus{
  outline: gray;
}

#submit {
  position: absolute;
  bottom: 14px;
  right: 30px;
  cursor: pointer;
}

.top-section{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
}

.feed {
  overflow-y: auto;
  overflow-x: hidden;
  width: 90%;
  padding: 0;
  padding-right: 10px;
}

.feed li {
  display: flex;
  background-color: transparent;
  width: 100%;
  padding: 7px;
  margin: 10px 0;
}

.feed p{
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  text-align: left;
}

.feed p.role{
  min-width: 100px;
}

.feed::-webkit-scrollbar {
  width: 6px; 
}

.feed::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 10px;
}

.feed::-webkit-scrollbar-thumb {
  background: #2A3B4C;
  border-radius: 10px;
}

.feed::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 600px) {
  .responsive-h3 {
    visibility: hidden;
    font-size: 0;
  }
  .responsive-h3::after {
    content: 'UBC Science Undergraduate Chatbot';
    visibility: visible;
    font-size: medium; 
  }
}


.lds-ring {
  display: inline-block;
  width: 10px;
  height: 10px;
}

.lds-ring div {
  position: absolute;
  bottom: 15px;
  right: 25px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
